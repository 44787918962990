@import './scss/variables.scss';

body {
  margin: 0px !important;
  background-color: $appBgColor !important;
}

.fullScreen {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.descriptionItemStyle {
  font-weight: bold;
  color: gray;
  margin-left: 35px;
}

.draggerStyle {
  margin-top: 25px !important;
  border: 1px dashed #333333 !important;
  border-radius: 5px !important;
}

.legendTable {
  display: flex;
  flex-direction: column;
  margin-top: 60px;
  align-items: center;
}

.legendTable {
  .ant-table-cell {
    padding: 0px !important;
  }
}
.cellHeaderTitleTxt {
  color: #545454;
}

.cellHeaderTitleStyle {
  margin-left: 20px;
  margin-right: 20px;
  @extend .cellHeaderTitleTxt;
}

.colorLegendTableStyle {
  font-weight: bold;
  font-size: 10px;
}

.expenseDistributionHeaderStyle {
  font-weight: bold;
}

.buttonColorRed {
  text-align: center !important;
  background-color: $tintColor !important;
  color: white !important;
  border-radius: 5px !important;
  border: 1px solid #5784e5 !important;
}

.ant-spin-dot-item {
  background-color: $tintColor !important;
}

.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused {
  box-shadow: none !important;
}

.ant-input:focus,
.ant-input-focused {
  box-shadow: none !important;
}

.dataClass {
  .ant-tabs-tab {
    border: 1px solid black !important;
    border-radius: 5px !important;
  }

  .ant-tabs-tab:hover {
    color: black !important;
  }

  .ant-tabs-tab-active {
    background-color: #ffffff !important;
    border: 1px solid $tintColor !important;
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: black !important;
    font-weight: bold;
  }
}

/* React datasheet */
.data-grid-container .data-grid {
  .cell {
    color: black !important;
    text-align: center !important;
    width: 105px;
    height: 30px !important;
    vertical-align: middle !important;
    border: 3px solid #fafafa !important;
  }
}

.pigsAndBarnPigsSoldTableWidth {
  .data-grid-container .data-grid {
    .cell {
      color: black !important;
      text-align: center !important;
      width: 105px;
      height: 30px !important;
      border: 3px solid #fafafa !important;
      vertical-align: middle !important;
    }
  }
}

.data-grid-container .data-grid .cell > input {
  height: 20px !important;
  border: none !important;
}

.data-grid-container .data-grid .cell.read-only {
  box-shadow: none !important;
  background-color: #d4d4d4 !important;
}

.sheetHeader {
  font-weight: bold !important;
  background-color: #efefef !important;
}

.pigsAndBarnPigsInTotalColHeader {
  @extend .sheetHeader;
  width: 150px !important;
}

.pigsAndBarPigsSoldTotalHeadCol {
  @extend .sheetHeader;
  width: 260px !important;
}

.pigsAndBarPigsSoldTotalCarcassWeightCol {
  @extend .sheetHeader;
  width: 150px !important;
}

.pigsAndBarPigsSoldDaysCol {
  @extend .sheetHeader;
  width: 60px !important;
}

.pigsAndBarPigsSoldTotalValueCol {
  @extend .sheetHeader;
  width: 130px !important;
}

.pigsAndBarPigsSoldAvgCol {
  @extend .sheetHeader;
  width: 170px !important;
}

.productionExpensesDescriptionCol {
  @extend .sheetHeader;
  width: 300px !important;
}

.productionExpensesAccountCol {
  @extend .sheetHeader;
  width: 250px !important;
}

.productionExpensesTotalCostCol {
  @extend .sheetHeader;
  width: 200px !important;
}

.userClass {
  .ant-tabs-top > .ant-tabs-nav::before,
  .ant-tabs-bottom > .ant-tabs-nav::before,
  .ant-tabs-top > div > .ant-tabs-nav::before,
  .ant-tabs-bottom > div > .ant-tabs-nav::before {
    border-bottom: 1px solid black !important;
  }

  .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab,
  .ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab {
    border: 1px solid black !important;
  }

  .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab-active,
  .ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab-active {
    background: white !important;
    border-bottom: 0 !important;
  }

  .ant-tabs-nav .ant-tabs-tab,
  .ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab {
    background: #dddddd !important;
    padding: 5px !important;
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #333333 !important;
  }
}

.requiredFieldBefore:before {
  content: '*';
  color: red;
  margin-right: 3px;
  font-weight: bold;
  font-size: 18px;
}

.requiredFieldAfter:after {
  content: '*';
  color: red;
  margin-left: 3px;
  font-weight: bold;
  font-size: 18px;
}

.ant-drawer-title {
  font-size: 20px !important;
}
.ant-table-thead .ant-table-cell {
  background-color: #e0dede !important;
}

.feedDeliveryFeedTypeCol {
  @extend .sheetHeader;
  width: 150px !important;
}

.feedDeliveryPoundsDeliveredCol {
  @extend .sheetHeader;
  width: 160px !important;
}

.feedDeliveryFeedDetailsCol {
  @extend .sheetHeader;
  width: 170px !important;
}

.feedDeliveryActualFeedCostCol {
  @extend .sheetHeader;
  width: 170px !important;
}

.feedDeliveryPoundsOfCorn {
  @extend .sheetHeader;
  width: 170px !important;
}

.feedDeliveryCostOfCornCol {
  @extend .sheetHeader;
  width: 190px !important;
}

.deathLossReasonCol {
  @extend .sheetHeader;
  width: 150px !important;
}

.deathLossDeadWeightCol {
  @extend .sheetHeader;
  width: 150px !important;
}

.ant-descriptions-item-content {
  color: gray !important;
  font-weight: bold;
}

.ant-descriptions-item-container .ant-descriptions-item-label,
.ant-descriptions-item-container .ant-descriptions-item-content {
  align-items: center !important;
}

/* react date picker */
.datePickerInput {
  width: 100px;
  display: flex;
  align-items: center;
  background-color: #ffffff;
  padding: 0 5px;
  cursor: pointer;
  input[type='text'] {
    width: 75px !important;
    border: none !important;
    outline: none !important;
    height: 24px !important;
    cursor: pointer;
    padding-left: 10px;
  }
}

.description {
  .ant-descriptions-title {
    font-size: 15px !important;
  }
  .ant-descriptions-item-label {
    font-size: 15px !important;
  }
  .ant-descriptions-item-content {
    color: gray !important;
    padding-left: 0px !important;
    padding-top: 0px !important;
    padding-right: 0px !important;
    font-size: 14px !important;
    margin-right: 0px !important;
    margin-left: 0px !important;
  }
}
.ant-descriptions-title {
  font-size: 22px !important;
}

.ant-descriptions-item-label {
  font-size: 17px !important;
}

.ant-descriptions-item-content {
  color: gray !important;
  padding-top: 0px !important;
  font-size: 18px !important;
}

.ant-descriptions-item-label {
  font-size: 16px !important;
}

.backBtn {
  .ant-btn-text:hover,
  .ant-btn-text:focus {
    background: none !important;
  }
}

@media print {
  @page {
    margin: 0 !important;
  }
  body {
    height: auto !important;
  }
  .hideWhilePrinting {
    display: none !important;
  }
}

.closeoutSummaryPrint {
  @media print {
    padding-top: 50px !important;
  }
}

.releaseForm {
  @media print {
    padding-top: 50px !important;
  }
}

.ant-select-focused .ant-select-selector,
.ant-select-selector:focus,
.ant-select-selector:active,
.ant-select-selector:hover,
.ant-select-open .ant-select-selector {
  box-shadow: none !important;
  border: 1px solid transparent !important;
}

.ant-select-selector {
  border-radius: 5px !important;
}

.ant-dropdown-menu-item {
  padding-left: 6px !important;
}

.disclaimer {
  margin-left: 90px;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: $tintColor !important;
  border-color: $tintColor !important;
}
