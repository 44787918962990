.tableStyle {
  width: 80vw;
  margin-top: 30px;
  margin-bottom: 20px;
}

.editButtonStyle {
  margin-left: 15px;
  margin-right: 15px;
  background-color: #ce0e2d !important;
  color: white !important;
  border-radius: 5px !important;
}

.deleteButtonStyle {
  border-color: #ce0e2d !important;
  margin-left: 15px;
  margin-right: 15px;
  border-radius: 5px !important;
}

.editButtonStyle:hover {
  background-color: rgb(216, 10, 10) !important;
}
