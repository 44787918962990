@import '../scss/variables.scss';

.headerContainer {
  height: 60px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  background-color: $tintColor;
  padding-left: 20px;
  font-weight: bold;
  color: #ffffff;

  .logoContainer {
    cursor: pointer;
    background-color: none;
  }

  .layoutContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding-left: 25px;
    height: 100%;
  }

  .menuList {
    font-size: 18px;
    text-transform: capitalize;
    height: 100%;
    display: flex;
    align-items: center;
    margin-left: 20px;
    .link {
      width: 100%;
      height: 100%;
      color: #ffffff;
      text-decoration: none;
      padding-left: 20px;
      padding-right: 20px;
      padding-bottom: 3px;
      display: flex;
      align-items: center;
    }
    .linkActive {
      @extend .link;
      background-color: rgba($color: #ffffff, $alpha: 0.4);
    }
  }

  .rightContainer {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .logoutContainer {
    display: flex;
    flex-direction: column;
    color: #ffffff !important;
    cursor: pointer;
    justify-content: center;
    align-items: center;
  }

  .logoutText {
    font-size: 13px;
    margin: 0px !important;
  }
}
