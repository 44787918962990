.formHeading {
  font-size: 28px;
  font-weight: 400;
  margin-bottom: 20px;
  text-align: center;
  padding-top: 10px;
}

.formContainer {
  border: 2px solid black;
  border-radius: 5px;
  padding: 30px;
  background-color: #fff;
  box-shadow: 3px 3px 3px rgba(50, 50, 50, 0.28);
}

.firstLoginTxt {
  font-size: 16px;
  width: 275px;
  text-align: center;
}

.buttonColorDisabled {
  text-align: center !important;
  background: #dddddd !important;
  color: #666666 !important;
  border: 2px solid #666666 !important;
  border-radius: 5px !important;
}
