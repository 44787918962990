.emailUsedForLoginStyle {
  color: 'black';
  margin-left: 2px;
  font-style: italic;
}

.addAndSaveButtonStyle {
  margin-left: 10px;
  margin-right: 20px;
  background-color: #ce0e2d !important;
  color: white !important;
  border-radius: 5px !important;
  padding-left: 26px !important;
  padding-right: 26px !important;
  font-size: 15px;
}

.cancelButtonStyle {
  margin-left: 20px;
  margin-right: 10px;
  background-color: #5078e4 !important;
  color: white !important;
  border-radius: 5px !important;
  padding-left: 20px !important;
  padding-right: 20px !important;
  font-size: 15;
}

.cancelButtonStyle:hover {
  background-color: rgb(39, 126, 155) !important;
  color: white;
}
.addAndSaveButtonStyle:hover {
  background-color: rgb(216, 10, 10) !important;
  color: white;
}
