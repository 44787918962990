.container {
  background-color: #ffefc2;
  padding: 3px 15px;
  margin-bottom: 15px;
  width: 380px;
  border: 1px solid #ff9900;
  border-radius: 5px;
}

.icon {
  margin-right: 10px;
  color: #ff9900 !important;
  font-size: 16px;
}
