@import '../scss/variables.scss';

.container {
  margin: 50px;
  background-color: $color1;
  border: 1px solid #eeeeee;
  border-radius: 5px;
  padding: 10px;
}

.closeOutSummary {
  margin-left: 40px;
  margin-right: 60px;
  margin-bottom: 10px;
}

.ant-descriptions-item-content {
  color: gray !important;
}

.columnStyle {
  margin-left: 50px;
  margin-right: 0px;
  margin-top: 25px;
}
