@import '../scss/variables.scss';

.commonButtonStyle {
  position: fixed;
  bottom: 30px;
}

.printButton {
  border-radius: 5px !important;
  border: 1px solid $color2 !important;
  color: $color2 !important;
}
