.btnContainer {
  margin-top: 25px;
  display: flex;
  justify-content: flex-end;
}

.cancelBtn {
  border-color: black !important;
  color: black !important;
  border-radius: 5px !important;
}

.loader {
  display: flex;
  justify-content: center;
  padding-top: 15px;
}
