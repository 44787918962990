.confirmPassSuffix {
  color: rgba(0, 0, 0, 0.45) !important;
  &:hover {
    color: #000 !important;
  }
}

.saveBtn {
  width: 85px;
  margin-right: 25px;
  margin-left: 225px;
  margin-top: 40px;
}
