@import '../../scss/variables.scss';

.deleteIconBtn {
  color: #cf2a27 !important;
  font-size: 16px;
  margin-left: 15px;
}

.newRowBtn {
  margin-top: 15px !important;
  border: 1px solid #2b78e4 !important;
  border-radius: 5px !important;
  color: #2b78e4 !important;
  font-weight: bold !important;
}

.bulkUploadBtn {
  @extend .newRowBtn;
  margin-left: 20px !important;
}

.commonBulkUploadBtn {
  margin-top: 15px !important;
  border: 1px solid #ce0e2d !important;
  border-radius: 5px !important;
  color: #ce0e2d !important;
  margin-left: 20px !important;
  font-weight: bold !important;
}

.finalOpRow {
  margin-top: 15px;
  border-top: 2px solid #7d7d7d;
  padding-top: 10px;
  width: 635px;
  padding-left: 5px;
}

.finalOpCol {
  width: 100px;
  text-align: center;
  border: 1px solid #392eff;
  margin-right: 5px;
  background-color: #f2f7ff;
  font-weight: bold;
  padding-top: 5px;
  padding-bottom: 5px;
}

.formattedValuesCols {
  display: flex;
  justify-content: center;
}

.heading {
  font-weight: bold;
  font-size: 18px;
}
.outputInfoHeader {
  font-weight: bold;
  margin: 15px 5px;
  font-size: 18px;
}

.chartHeader {
  margin-bottom: 15px;
  text-align: center;
  font-weight: bold;
  font-size: 16px;
  margin-left: 30px;
}

.closeoutSummaryContainer {
  display: flex;
  justify-content: flex-end;
  margin-top: 50px;
  margin-bottom: 25px;
  width: 900px;
}

.runningInventoryTxt {
  margin-left: 15px;
  margin-right: 10px;
}
