@import '../scss/variables.scss';

.container {
  position: relative;
  margin: 60px;
  background-color: $color1;
  border: 1px solid #eeeeee;
  border-radius: 5px;
  margin-top: 30px;
}

.topContainer {
  padding: 20px;
  border-bottom: 2px solid #999999;
  background-color: #ffffff;
}

.bottomContainer {
  padding: 20px;
}

.heading {
  font-weight: bold;
  font-size: 18px;
}

.saveBtn {
  display: flex;
  justify-content: center;
  margin-top: 35px;
}

.loader {
  display: flex;
  justify-content: center;
  padding-top: 15px;
}

.printPreviewContainer {
  background-color: #ffffff;
  margin-top: 25px;
  width: 1120px;
  margin-left: auto;
  margin-right: auto;
}
